var render = function () {
  var _vm$pax$travelFlyer, _vm$pax$travelFlyer2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex-center justify-content-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-50"
  }, [_c('div', {
    staticClass: "font-medium-3"
  }, [_c('span', [_vm._v(_vm._s("".concat(_vm.paxIndex + 1, ". ")))]), _c('code', {
    staticClass: "text-body font-italic bg-transparent pl-0 pr-25"
  }, [_vm._v(" " + _vm._s("".concat(_vm.pax.paxType ? "(".concat(_vm.$t("reservation.".concat(_vm.pax.paxType)), ")") : '')) + " ")]), _c('b', [_vm._v(_vm._s("".concat(_vm.pax.lastName, " / ").concat(_vm.pax.firstName, " ").concat(_vm.pax.title || '', " ")))])])]), ['1S', '1S_CTRL', '1G', 'QH'].includes(_vm.reservationsData.source) && ['ADULT', 'CHILD'].includes(_vm.pax.paxType) && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" | "), _c('b-button', {
    staticClass: "text-info py-50 px-50",
    attrs: {
      "variant": "flat-info",
      "size": "md",
      "pill": "",
      "disabled": _vm.isBookingOutSystem || _vm.reservationsData.status === 'CANCEL'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('openEditTravelFlyPassengerModalHandle');
      }
    }
  }, [_vm._v(" " + _vm._s(['1S', '1S_CTRL'].includes(_vm.reservationsData.source) ? _vm.$t('reservation.addBSV') : _vm.$t('reservation.addTTV')) + " ")])], 1) : _vm._e(), ['VJ'].includes(_vm.reservationsData.source) && ['INFANT'].includes(_vm.pax.paxType) && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" | "), _c('b-button', {
    staticClass: "text-info py-50 px-50",
    attrs: {
      "variant": "flat-info",
      "size": "md",
      "pill": "",
      "disabled": _vm.isBookingOutSystem || _vm.reservationsData.status === 'CANCEL'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('handleDeleteInfant');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.removeInfant')) + " ")])], 1) : _vm._e()]), _c('div', [!_vm.isEmpty(_vm.pax.travelFlyer) ? _c('small', {
    staticClass: "d-flex align-items-center ml-1"
  }, [_c('em', [_vm._v(_vm._s(_vm.$t('reservation.membershipCard')) + ": ")]), _c('span', {
    staticClass: "fw-700 mx-25"
  }, [_vm._v(" " + _vm._s((_vm$pax$travelFlyer = _vm.pax.travelFlyer) === null || _vm$pax$travelFlyer === void 0 ? void 0 : _vm$pax$travelFlyer.tier) + " " + _vm._s((_vm$pax$travelFlyer2 = _vm.pax.travelFlyer) === null || _vm$pax$travelFlyer2 === void 0 ? void 0 : _vm$pax$travelFlyer2.provider) + " " + _vm._s(_vm.pax.travelFlyer.number || _vm.pax.travelFlyer) + " ")]), _vm.pax.travelFlyer.tierDescription ? _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" (" + _vm._s(_vm.pax.travelFlyer.tierDescription) + ") ")]) : _vm._e(), ['1S', '1S_CTRL', 'QH'].includes(_vm.reservationsData.source) ? _c('b-button', {
    staticClass: "p-25 ml-50",
    attrs: {
      "variant": "flat-danger",
      "size": "md",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('deleteBSVHandle');
      }
    }
  }, [_vm._v(" " + _vm._s(['1S', '1S_CTRL'].includes(_vm.reservationsData.source) ? _vm.$t('reservation.addBSV') : _vm.$t('reservation.addTTV')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.shouldDisplayPassport(_vm.reservationsData, _vm.pax) ? _c('div', {
    staticClass: "ml-1"
  }, _vm._l(_vm.getPassport(_vm.pax), function (doc, dIndex) {
    return _c('div', {
      key: dIndex,
      attrs: {
        "id": "reservation.Passport"
      }
    }, [_vm._v(" " + _vm._s("".concat(_vm.getPassport(_vm.pax).length > 1 ? "".concat(_vm.$t('reservation.Passport'), " ").concat(dIndex + 1) : "".concat(_vm.$t('reservation.Passport')))) + ": " + _vm._s(doc.number) + " " + _vm._s(doc.nationality) + " " + _vm._s(_vm.convertISODateTime(doc.endDate, 'UTC').date || doc.endDate) + " " + _vm._s(doc.isCountry) + " ")]);
  }), 0) : _vm._e(), ['ADULT', 'INFANT', 'CHILD'].includes(_vm.pax.paxType) ? _c('div', {
    staticClass: "text-warning ml-1",
    attrs: {
      "id": "reservation.Accompanying_adult"
    }
  }, [_vm.pax.birthday ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.DoB')) + ": " + _vm._s(_vm.convertISODateTime(_vm.pax.birthday, 'UTC').date) + " ")]) : _vm._e(), _vm.getAccompanyingPerson(_vm.reservationsData.paxLists, _vm.pax) ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("reservation.Accompanying_".concat(['INFANT'].includes(_vm.pax.paxType) ? 'adult' : 'infant'))) + " " + _vm._s(_vm.getAccompanyingPerson(_vm.reservationsData.paxLists, _vm.pax)) + " ")]) : _vm._e()]) : _vm._e()]), _vm.ancillaryServicesOfPax && _vm.ancillaryServicesOfPax.length ? _c('div', {
    staticClass: "d-flex flex-column my-25 ml-1"
  }, _vm._l(_vm.ancillaryServicesOfPax, function (ancillaryItem, ancillaryIndex) {
    return _c('span', {
      key: ancillaryIndex,
      staticClass: "font-small-4"
    }, [_vm._v(" " + _vm._s(ancillaryItem) + " ")]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }